import React from 'react'
import { Link } from 'react-router-dom'
import './Thanks.css'
import Fade from 'react-reveal/Fade';


function Thanks() {
    return (
        <div>
            <div className='container-fluid thanks-div1'>
                <div className='container'>
                    <Fade top distance="6%" duration={1200}>
                        <h1 className='thanks-text1'>Thank You</h1>
                    </Fade >
                    <Fade top distance="6%" duration={1200}>
                        <p className='thanks-text2'>You can reach me out though my email <span><a href="mailto:hi@imqasim.com">hi@imqasim.com</a></span></p>
                    </Fade>
                    <Fade top distance="6%" duration={1200}>

                        <div className='d-flex justify-content-center'>

                            <Link to='/' className='watch-banner-text mx-auto' >View Portfolio <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                            </svg>
                            </Link>
                        </div>
                    </Fade>
                </div>
            </div>
        </div >
    )
}

export default Thanks