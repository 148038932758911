export const portfolio = [
    // {
    //     id: 1,
    //     title: "Dukes Website Design",
    //     img: "ProjectsImages/89af03133232499 (1).png",
    //     link: "https://www.behance.net/gallery/133232499/Dukes-UI-UX"
    // },
    // {
    //     id: 2,
    //     title: "Complete Food App UI UX Design",
    //     img: "ProjectsImages/b3d1bd135054153 (1).png",
    //     link: "https://www.behance.net/gallery/135054153/Food-App-UI-UX-Design"

    // },
    // {
    //     id: 3,
    //     title: "Branding & Social Media Design",
    //     img: "ProjectsImages/f18feb134610195 (2).png",
    //     link: "https://www.behance.net/gallery/134610195/Branding-and-Social-Media-Design"
    // },
    // {
    //     id: 4,
    //     title: "Packaging Design For CBD Products",
    //     img: "ProjectsImages/23b671135722285 (3).png",
    //     link: "https://www.behance.net/gallery/135722285/Packaging-Design-CBD-Products"

    // },
    // {
    //     id: 5,
    //     title: "Branding & Social Media Design",
    //     img: "ProjectsImages/f18feb134610195 (4).png",
    //     link: "https://www.behance.net/gallery/134610195/Branding-and-Social-Media-Design"

    // },
    // {
    //     id: 6,
    //     title: "",
    //     img: "ProjectsImages/23b671135722285 (4).png",
    //     link: ""
    // },
    {
        id: 2,
        title: "2X",
        // img: "ProjectsImages/payever.png",
        link: "Wh2EgqD-55k"
    },
    {
        id: 8,
        title: "3X",
        // img: "ProjectsImages/Juice.png",
        link: "j6Qf9j5pAGw"
    },
    {
        id: 5,
        title: "4X",
        // img: "ProjectsImages/halfpastnine.png",
        link: "nKxtciHbXZc"
    }, 
    {
        id: 3,
        title: "5X",
        // img: "ProjectsImages/talkasiavoip.jpg",
        link: "ZDX_jlV4VyE"
    },
//     {
//         id: 1,
//         title: "CAL Branding & App Design",
//         img: "ProjectsImages/calapp.png",
//         link: "https://www.behance.net/gallery/164844909/CAL-Branding-and-App-Design"
//     },
//     {
//         id: 6,
//         title: "Branding and CRM System Design",
//         img: "ProjectsImages/Ibuild.png",
//         link: "https://www.behance.net/gallery/164846185/IBuild-Branding-Social-Media-and-CRM-System"
//     },
//     {
//         id: 7,
//         title: "Mobile App & Branding",
//         img: "ProjectsImages/sheikh.png",
//         link: "https://www.behance.net/gallery/164844189/Sheikh-Chargha-Branding-Social-Media-App-Design"
//     },
//     {
//         id: 4,
//         title: "Branding and Website Design",
//         img: "ProjectsImages/Advanceamz.png",
//         link: "https://www.behance.net/gallery/164843715/Advance-AMZ-Agency-Website-and-Branding"
//     },
    
//      {
//         id: 9,
//          title: "Music Learning App Design",
//         img: "ProjectsImages/musicapp.png",
//          link: "https://www.behance.net/gallery/164844359/Audio-Cases-App-UI-Design"
//     },
//      {
//         id: 10,
//          title: "Branding & Website Design",
//         img: "ProjectsImages/BrainSouls (1).png",
//          link: "https://www.behance.net/gallery/164844461/BrainSouls-Branding-Website-and-Social-Media-Design"
//     }
]