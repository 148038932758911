import React, { useState, useCallback, useEffect } from 'react'
import "./Home.css"
// import bannerimg from "../PortfolioPics/Group 48095395.png"
import halfpastlogo from "../PortfolioPics/Group 48095392.png"
import brainlogo from "../PortfolioPics/Group 1000001818 (1).png"
import amzlogo from "../PortfolioPics/Group.png"
import zoologo from "../PortfolioPics/Group 1000001834.png"
import tdo from "../PortfolioPics/TDO.jpg"
import Yae from "../PortfolioPics/Yae Organic.jpg"
import skidee from "../PortfolioPics/SKIDEE.jpg"
import Wididi from "../PortfolioPics/Wididi.jpg"
import Karaoking from "../PortfolioPics/Karaoking.jpg"
import Yunioo from "../PortfolioPics/Yunioo.jpg"
import Masingo from "../PortfolioPics/Masingo.jpg"
import Mden from "../PortfolioPics/Mden.jpg"
import Neo from "../PortfolioPics/Neo Direct.jpg"
import FWI from "../PortfolioPics/FWI.jpg"
import baby from "../PortfolioPics/Babyseaters.jpg"
import AutoMotivated from "../PortfolioPics/AutoMotivated.jpg"
import Aerolite from "../PortfolioPics/Aerolite.jpg"
import protuff from "../PortfolioPics/protuff.png"
import { portfolio } from "./Portfolio"
import aboutimg from "../PortfolioPics/Saqlain bahi.jpg"
import designlogo1 from "../PortfolioPics/Group 1000002111.png"
import designlogo2 from "../PortfolioPics/Group 1000002113.png"
import designlogo3 from "../PortfolioPics/Group 1000002114.png"
import designlogo4 from "../PortfolioPics/Group 1000002115.png"
import designlogo5 from "../PortfolioPics/Group 1000002116.png"
import designlogo6 from "../PortfolioPics/Group 1000002117.png"
import designlogo7 from "../PortfolioPics/Group 1000002118.png"

import { loadFull } from "tsparticles";
import AOS from "aos"
import 'aos/dist/aos.css'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import WOW from "wowjs"
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'
import Ticker from "react-ticker";
import Marquee from "react-fast-marquee";
import TextAnimation from "react-text-animations";

// import 




function Home() {

  const [isOpen, setOpen] = useState(false)
   const [isOpen1, setOpen1] = useState(false)
    const [isOpen2, setOpen2] = useState(false)

    const [isOpen3, setOpen3] = useState(false)

    const [isOpen4, setOpen4] = useState(false)
  const [active, setactive] = useState(false)

  const [filterdata, setFilterData] = useState(portfolio);
  const [noOfCard, setnoOfCard] = useState(10);

  const sliceCard = filterdata.slice(0, noOfCard);

  const init = useCallback(async (engine) => {
    await loadFull(engine)
  })

  useEffect(() => {
    AOS.init({ duration: 2000 })
    new WOW.WOW({
      live: true
    }).init()
  }, [])


  return (
    <div>

      <div className='container-fluid home-div1' id='home'>
       
        <div className='container home-con-div1'>
          <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="25P8wq-AJH0" onClose={() => setOpen(false)} />

          <div className="row align-items-center">
            <div className='col-12'>
              <div>
                <Fade top distance="6%" duration={1200}>
                  <h1 className='banner-text1 '>👋 I’m <span>Saqlain.</span></h1>
                </Fade>
                <Fade top distance="6%" duration={1200}>
                  <h1 className="banner-text2 "> <TextAnimation.Slide target="AMAZON" text={['Amazon', 'EBAY', 'Walmart']} animation={{
                    duration: 1000,
                    delay: 2000,
                    timingFunction: 'ease-out',
                   
                  }}>  AMAZON </TextAnimation.Slide>  PPC EXPERT
                  </h1>
                </Fade>
                <Fade top distance="6%" duration={1200}>
                  <p className='banner-para1 '>I am specialized in PPC, Listing Optimmisation, Product Ranking, <br className="d-sm-block d-none"></br> Campaign Creation & Management</p>
                </Fade>
                <Fade top distance="6%" duration={1200}>
                  <div className='d-flex justify-content-center'>
                    <a className='watch-banner-text mx-auto' onClick={() => setOpen(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                    </svg>
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* section #2 */}
      <Fade top distance="3%" duration={1000}>

        <div className='container-fluid home-work-div1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-3 col-12 ' >
                <h1 className='work-text2'>Brands</h1>
                <h1 className='work-text1' >I Worked With</h1>
                
              </div>
              <div className='col-lg-8 col-12 '>
                <div className='row work-company-maindiv'>
                  <Marquee>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div >
                            <img src={protuff} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                  <div className=' work-col-div' >
                    <a href="" target="_blank">
                      <div className='work-company-div1 p-4'>
                        <div >
                          <img src={tdo} className="img-fluid company-logo1" alt="" />
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className=' work-col-div' >
                    <a href="" target="_blank">
                      <div className='work-company-div1 p-4'>
                        <div>
                          <img src={AutoMotivated} className="img-fluid company-logo1" alt="" />
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className=' work-col-div' >
                    <a href="" target="_blank">
                      <div className='work-company-div1 p-4'>
                        <div>
                          <img src={skidee} className="img-fluid company-logo1" alt="" />
                        </div>
                      </div>
                    </a>
                  </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Masingo} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Mden} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Karaoking} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Yae} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={baby} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>


                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Neo} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Wididi} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={FWI} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Yunioo} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className=' work-col-div' >
                      <a href="" target="_blank">
                        <div className='work-company-div1 p-4'>
                          <div>
                            <img src={Aerolite} className="img-fluid company-logo1" alt="" />
                          </div>
                        </div>
                      </a>
                    </div>
                  

                    </Marquee>

                </div>
              </div>
            </div>
          </div>
        </div>

      </Fade>
      <Fade top  >
        <div className="vert-line d-sm-block d-none" ></div>
      </Fade>


      {/* section #3 */}

      <div className='container-fluid portfolio-div1' >

        <div className='container' id='figmafolio'>
          <Fade top distance="6%" duration={1200}>
            <h1 className='portfolio-text1' >Portfolio</h1>
          </Fade>
          {/* <Fade top distance="6%" duration={1200}>
            <Link to="/portfolio" className='explore-text1 d-sm-block d-none' >Explore All <svg className='ml-4' width="37" height="14" viewBox="0 0 37 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M37 -2.571e-06L37 14L35 14L35 -2.48357e-06L37 -2.571e-06ZM33 7L27 13L25.59 11.59L29.17 8L-2.62268e-07 8L-3.49691e-07 6L29.17 6L25.59 2.41L27 0.999999L33 7Z" fill="#2F2F2F" />
            </svg>
            </Link>
          </Fade> */}

          <div className='row'>
            
            {/* {
              sliceCard.map((port) => {
                return ( */}

                <div className='col-lg-6 col-12 d-flex justify-content-center' >
                                        <ModalVideo channel='youtube' autoplay isOpen={isOpen1} videoId='Wh2EgqD-55k' onClose={() => setOpen1(false)} />
                                        <Fade top distance="3%" duration={1200}>
                                            <div>
                                                <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                                    <div>
                                                        <h1 className=' x2-text'>2X</h1>
                                                        <h1 className='growths-text'>Growth</h1>
                                                        <div className='d-flex justify-content-center mt-4'>
                                                            <a className='watch-banner-text mx-auto' onClick={() => setOpen1(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                            </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>


                        <div className='col-lg-6 col-12 d-flex justify-content-center' >
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen2} videoId='j6Qf9j5pAGw' onClose={() => setOpen2(false)} />
                            <Fade top distance="3%" duration={1200}>
                                <div>
                                    <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                        <div>
                                            <h1 className=' x2-text'>3X</h1>
                                            <h1 className='growths-text'>Growth</h1>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <a className='watch-banner-text mx-auto' onClick={() => setOpen2(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>


                        <div className='col-lg-6 col-12 d-flex justify-content-center' >
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen3} videoId='nKxtciHbXZc' onClose={() => setOpen3(false)} />
                            <Fade top distance="3%" duration={1200}>
                                <div>
                                    <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                        <div>
                                            <h1 className=' x2-text'>4X</h1>
                                            <h1 className='growths-text'>Growth</h1>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <a className='watch-banner-text mx-auto' onClick={() => setOpen3(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>



                        <div className='col-lg-6 col-12 d-flex justify-content-center' >
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen4} videoId='ZDX_jlV4VyE' onClose={() => setOpen4(false)} />
                            <Fade top distance="3%" duration={1200}>
                                <div>
                                    <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                        <div>
                                            <h1 className=' x2-text'>5X</h1>
                                            <h1 className='growths-text'>Growth</h1>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <a className='watch-banner-text mx-auto' onClick={() => setOpen4(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>

                 
                {/* )
              })
            } */}
          </div>
          {/* <Fade top distance="6%" duration={1200}>
            <Link to='/portfolio' className='explore-text1 d-sm-none d-block mx-auto' >Explore All <svg className='ml-3' width="37" height="14" viewBox="0 0 37 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M37 -2.571e-06L37 14L35 14L35 -2.48357e-06L37 -2.571e-06ZM33 7L27 13L25.59 11.59L29.17 8L-2.62268e-07 8L-3.49691e-07 6L29.17 6L25.59 2.41L27 0.999999L33 7Z" fill="#2F2F2F" />
            </svg>
            </Link>
          </Fade> */}
        </div>
      </div>


      {/* section # 4 */}

      <div className='container-fluid  about-me-div1' id="about-me">
        <div className='row'>
          <Fade left distance="2%" duration={1200}>
            <div className='col-lg-6 col-12 about-img-div1' >
              {/* <div className='about-img-div'> */}
                <img src={aboutimg} className='about-img img-fluid' alt="" />

               
              {/* </div> */}
            </div>
          </Fade>
          <Fade right distance="2%" duration={1200}>
            <div className='col-lg-6 col-12 about-text-div1' >
              <div>
                <h1 className='about-me-text1'>About Me</h1>
                <h1 className='about-me-text2'>Saqlain Ali</h1>
                <p className='about-me-text3'>Focused and resourceful PPC Expert with a strong record of client satisfaction and successful project completion. Thorough knowledge base and practical skill in effective PPC, SEO techniques and strategies. Able to function well as an independent worker or as part of a professional systems management team.</p>
                <p className='about-me-text4'>I partner with Amazon, Walmart, and eBay Sellers to help them increase sales and realise their success in eCommerce Selling Business by generating data-driven PPC and SEO strategies.</p>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      {/* section # 5 */}

      <div className='container-fluid design-div1'>
        <div className='container-fluid design-div2'>
          <Fade top distance="6%" duration={1200}>
            <h1 className='design-text1' >Tools I Work With</h1>
          </Fade>
          <Fade top distance="6%" duration={1200}>
            <p className='design-text2' >I work with different marketing and project management tools</p>
          </Fade>
          <div className='row  deignlogo-div'>
            <Fade top distance="6%" duration={1200}>
              <div >
                <img src={designlogo1} className="img-fluid design-logo" alt="" />
                <p className='designlogos-text2'>Perpetua</p>
              </div>
              <div >
                <img src={designlogo2} className="img-fluid design-logo" alt="" />
                <p className='designlogos-text2'> Sellics </p>
              </div>
              <div >
                <img src={designlogo3} className="img-fluid design-logo" alt="" />
                <p className='designlogos-text2'> Teikametrics </p>
              </div>
              <div >
                <img src={designlogo4} className="img-fluid design-logo" alt="" />
                <p className='designlogos-text2'>Pacvue </p>
              </div>
              <div >
                <img src={designlogo5} className="img-fluid design-logo" alt="" />
                <p className='designlogos-text2'> Slack </p>
              </div>
              <div >
                <img src={designlogo6} className="img-fluid design-logo" alt="" />
                <p className='designlogos-text2'> Asana </p>
              </div>
              <div >
                <img src={designlogo7} className="img-fluid design-logo" alt="" />
                <p className='designlogos-text2'> Microsoft Teams </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>

    </div>


  )
}

export default Home