import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <div>
            <div className='container-fluid footer-div1' id='contact'>
                <div className='container footer-div2'>
                    <div className='row '>
                        <div className='col-md-6 col-12 row justify-content-md-start justify-content-center p-0'>
                            <div className="">
                                <h1 className='footer-text1'>Contact</h1>
                                <a href="mailto:meet4saqlain@gmail.com"> <h1 className='footer-text2'>meet4saqlain@gmail.com</h1></a>
                            </div>
                        </div>
                        <div className='col-md-6 col-12 d-flex justify-content-md-end justify-content-center p-0'>
                            <div >
                                <div className='d-flex footer-social-div justify-content-md-end justify-content-center'>
                                   
                                    <div>
                                        <a href="https://www.linkedin.com/in/saqlain-ali-979707193/" target="_blank"><svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 0C8.50646 0 0 8.50646 0 19C0 29.4935 8.50646 38 19 38C29.4935 38 38 29.4935 38 19C38 8.50646 29.4935 0 19 0ZM14.349 26.8751H10.5015V14.4934H14.349V26.8751ZM12.4015 12.9734C11.1862 12.9734 10.4005 12.1125 10.4005 11.0477C10.4005 9.96115 11.21 9.12594 12.4509 9.12594C13.6919 9.12594 14.4519 9.96115 14.4756 11.0477C14.4756 12.1125 13.6919 12.9734 12.4015 12.9734ZM28.401 26.8751H24.5535V20.0133C24.5535 18.4161 23.9954 17.3316 22.6041 17.3316C21.5413 17.3316 20.9099 18.0658 20.6308 18.7724C20.5279 19.0238 20.5022 19.38 20.5022 19.7343V26.8731H16.6527V18.4419C16.6527 16.8961 16.6032 15.6037 16.5518 14.4915H19.8946L20.0707 16.2114H20.1479C20.6546 15.4039 21.8955 14.2124 23.9717 14.2124C26.503 14.2124 28.401 15.9085 28.401 19.5542V26.8751Z" fill="#1772CB" />
                                        </svg>


                                        </a>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <p className='footer-qasim-text'>© 2023 Im Saqlain | All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer