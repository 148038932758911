import React, { useState } from 'react'
import './Home.css'
import { InlineWidget } from 'react-calendly'



function Calandly() {

  return (
    <div>
      <div className="container-fluid cal-div1">
        <h1 className='book-text'>Book A Meeting</h1>
        <div className='book-div2'>
          <InlineWidget url="https://calendly.com/iamqasim/30min" styles={{ height: '700px' }}></InlineWidget>
        </div>
      </div>
    </div>

  )
}

export default Calandly
