import React, { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import TextAnimation from "react-text-animations";


function NavBar() {

  const [activeStatus, setactiveStatus] = useState("home");
  const [toggle, settoggle] = useState(true);
  const [drop, setdrop] = useState(true)
  const [drops, setdrops] = useState(true)


  return (
    <div>

      <nav class="navbar navbar-div1 navbar-expand-lg navbar-light ">
        <Link
          class="navbar-brand portfolio-logo "
          to="/"
          onClick={() => { setactiveStatus("home"); setdrop(true); setdrops(true) }}
        >
          {/* <TextAnimation.Slide target="QASIM" text={['QASIM', 'DESIGNER']} animation={{
            duration: 1000,
            delay: 2000,
            timingFunction: 'ease-out',
          }}> */}
          IAM <span style={{ 'color':'#F64B65'}}>SAQLAIN</span> 
          {/* </TextAnimation.Slide> */}

        </Link>

        <button
          class="navbar-toggler "
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav navbar-ul">

            <li
              className={`${activeStatus === "home"
                ? "nav-item active nav-li1"
                : "nav-item nav-li1"
                }`}
            >

              <HashLink
                class="nav-link"
                to="/#home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
                onClick={() => { setactiveStatus("home"); setdrop(true); setdrops(true) }}
              >
                Home
              </HashLink>
            </li>

            <li
              className={`${activeStatus === "about"
                ? "nav-item active nav-li1"
                : "nav-item nav-li1"
                }`}
            >
              <HashLink
                class="nav-link"
                to="/#about-me"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
                onClick={() => { setactiveStatus("home"); setdrop(true); setdrops(true) }}
              >
                About
              </HashLink>
            </li>

            <li
              className={`${activeStatus === "portfolio"
                ? "nav-item active nav-li1"
                : "nav-item nav-li1"
                }`}
            >
              <Link
                class="nav-link"
                to="/portfolio"
                onClick={() => { setactiveStatus("portfolio"); setdrop(true); setdrops(true) }}
              >
                Portfolio
              </Link>
            </li>


            <li
              className={`${activeStatus === "about"
                ? "nav-item active nav-li1"
                : "nav-item nav-li1"
                }`}
            >
              <HashLink
                class="nav-link"
                to="/#contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
                onClick={() => { setactiveStatus("home"); setdrop(true); setdrops(true) }}
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>





      {/* mobile navbar */}

      <nav class="navbar mobile-navbar-div1 d-flex d-lg-none navbar-expand-lg navbar-light ">
        <Link
          class="navbar-brand mobile-portfolio-logo  "
          to="/#home"
          onClick={() => { setactiveStatus("home"); setdrop(true); setdrops(true) }}
        >
         
          IAM <span style={{ 'color': '#F64B65' }}>SAQLAIN</span> 
         
        </Link>
        <button
          class="navbar-toggler mr-sm-4 mr-2"
          type="button"
          // data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => { settoggle(!toggle); setdrop(true); setdrops(true) }}
        >
          <svg width="39" height="22" viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H37.5" stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M11.5 11L37.5 11" stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M19.5 21L37.5 21" stroke="white" stroke-width="2" stroke-linecap="round" />
          </svg>

        </button>


        <div
          class={`${toggle
            ? "navbar-collapse mobile-navbar-div2"
            : "navbar-collapse mobile-navbar-div2 show"
            } `}
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mx-auto">

            <li
              className={`${activeStatus === "home"
                ? "nav-item mobile-nav-item active home1"
                : "nav-item mobile-nav-item home1"
                }`}
            >
              <HashLink
                class="nav-link"
                to="/#home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
                onClick={() => { setactiveStatus("home"); settoggle(!toggle); setdrop(true); setdrops(true) }}
              >
                Home
              </HashLink>
            </li>

            <li
              className={`${activeStatus === "about"
                ? "nav-item mobile-nav-item active home1"
                : "nav-item mobile-nav-item home1"
                }`}
            >
              <HashLink
                class="nav-link"
                to="/#about-me"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
                onClick={() => { setactiveStatus("home"); settoggle(!toggle); setdrop(true); setdrops(true) }}
              >
                About Us
              </HashLink>
            </li>

            <li
              className={`${activeStatus === "portfolio"
                ? "nav-item mobile-nav-item active home1"
                : "nav-item mobile-nav-item home1"
                }`}
            >
              <HashLink
                class="nav-link"
                to="/portfolio"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
                onClick={() => { setactiveStatus("portfolio"); settoggle(!toggle); setdrop(true); setdrops(true) }}
              >
                Portfolio
              </HashLink>
            </li>


            <li
              className={`${activeStatus === "contact"
                ? "nav-item mobile-nav-item active home1"
                : "nav-item mobile-nav-item home1"
                }`}
            >
              <HashLink
                class="nav-link"
                to="/#contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
                onClick={() => { setactiveStatus("home"); settoggle(!toggle); setdrop(true); setdrops(true) }}
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
