import React, { useState, useEffect } from "react"
import './PortfolioComponents/Home.css'
import Home from "./PortfolioComponents/Home"
import NavBar from "./PortfolioComponents/NavBar"
import Footer from "./PortfolioComponents/Footer"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PortfolioPage from "./PortfolioComponents/PortfolioPage";
import Thanks from "./PortfolioComponents/Thanks";
import ScrollToTop from './PortfolioComponents/ScrollToTop'
import Calandly from './PortfolioComponents/Calandly'


function App() {

  return (
    <div >
      <BrowserRouter>

        <ScrollToTop></ScrollToTop>

        <NavBar></NavBar>
        <Routes>

          <Route path="/" element={<Home></Home>} />
          <Route path="/portfolio" element={<PortfolioPage></PortfolioPage>} />
          <Route path="/thankyou" element={<Thanks></Thanks>} />
          <Route path="/bookameeting" element={<Calandly></Calandly>} />

        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
