import React, { useState } from 'react'
import { portfolio } from "./Portfolio"
// import workimg from "../PortfolioPics/Group 48095388.png"
import NavBar from './NavBar'
import Footer from './Footer'
import Fade from 'react-reveal/Fade';
import ModalVideo from 'react-modal-video'



function PortfolioPage() {
    const [isOpen1, setOpen1] = useState(false)
    const [isOpen2, setOpen2] = useState(false)

    const [isOpen3, setOpen3] = useState(false)

    const [isOpen4, setOpen4] = useState(false)

    
    const [active, setactive] = useState(false)


    const [data, setdata] = useState(portfolio)
    return (
        <div>

            <div className='container-fluid portfoliopage-div1 ' >
                <div className='container' id='figmafolio'>
                    <Fade top distance="6%" duration={1200}>
                        <h1 className='portfolio-text1' >Portfolio</h1>
                    </Fade>

                    <div className='row'>
                        {/* {
                            data.map((port) => {
                                return ( */}
                                    <div className='col-lg-6 col-12 d-flex justify-content-center' >
                                        <ModalVideo channel='youtube' autoplay isOpen={isOpen1} videoId='Wh2EgqD-55k' onClose={() => setOpen1(false)} />
                                        <Fade top distance="3%" duration={1200}>
                                            <div>
                                                <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                                    <div>
                                                        <h1 className=' x2-text'>2X</h1>
                                                        <h1 className='growths-text'>Growth</h1>
                                                        <div className='d-flex justify-content-center mt-4'>
                                                            <a className='watch-banner-text mx-auto' onClick={() => setOpen1(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                            </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>


                        <div className='col-lg-6 col-12 d-flex justify-content-center' >
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen2} videoId='j6Qf9j5pAGw' onClose={() => setOpen2(false)} />
                            <Fade top distance="3%" duration={1200}>
                                <div>
                                    <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                        <div>
                                            <h1 className=' x2-text'>3X</h1>
                                            <h1 className='growths-text'>Growth</h1>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <a className='watch-banner-text mx-auto' onClick={() => setOpen2(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>


                        <div className='col-lg-6 col-12 d-flex justify-content-center' >
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen3} videoId='nKxtciHbXZc' onClose={() => setOpen3(false)} />
                            <Fade top distance="3%" duration={1200}>
                                <div>
                                    <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                        <div>
                                            <h1 className=' x2-text'>4X</h1>
                                            <h1 className='growths-text'>Growth</h1>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <a className='watch-banner-text mx-auto' onClick={() => setOpen3(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>



                        <div className='col-lg-6 col-12 d-flex justify-content-center' >
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen4} videoId='ZDX_jlV4VyE' onClose={() => setOpen4(false)} />
                            <Fade top distance="3%" duration={1200}>
                                <div>
                                    <div className=" d-flex justify-content-center align-items-center portfolio-img-div" >
                                        <div>
                                            <h1 className=' x2-text'>5X</h1>
                                            <h1 className='growths-text'>Growth</h1>
                                            <div className='d-flex justify-content-center mt-4'>
                                                <a className='watch-banner-text mx-auto' onClick={() => setOpen4(true)}>Watch Video <svg className="ml-3" width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M40.3536 4.35356C40.5488 4.15829 40.5488 3.84171 40.3536 3.64645L37.1716 0.464469C36.9763 0.269207 36.6597 0.269207 36.4645 0.464469C36.2692 0.659731 36.2692 0.976314 36.4645 1.17158L39.2929 4L36.4645 6.82843C36.2692 7.02369 36.2692 7.34027 36.4645 7.53554C36.6597 7.7308 36.9763 7.7308 37.1716 7.53554L40.3536 4.35356ZM-4.37114e-08 4.5L40 4.5L40 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>

                                    
                                {/* )
                            })
                        } */}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default PortfolioPage